
















































































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_ORGANIZATION, GET_ORGANIZATION } from '@/modules/organization/store';
import { Organization } from '../../types';
import CardRow from '@/components/CardRow.vue';
import { ApiResponse } from '@/components/types';
import PhoneRow from '@/components/PhoneRow.vue';
import EmailRow from '@/components/form/EmailRow.vue';
import AddPhoneNumberDialog from '@/modules/organization/admin/components/AddPhoneNumberDialog.vue';
import AddFaxNumberDialog from '@/modules/organization/admin/components/AddFaxNumberDialog.vue';
import AddEmailDialog from '@/modules/organization/admin/components/AddEmailDialog.vue';
import AddBankAccountDialog from '@/modules/organization/admin/components/AddBankAccountDialog.vue';
import RelocateDialog from '@/modules/organization/admin/components/RelocateDialog.vue';
import LetterheadCard from '@/modules/organization/admin/components/LetterheadCard.vue';

const OrganizationStore = namespace('organization');

@Component({
  components: { LetterheadCard, RelocateDialog, AddBankAccountDialog, AddEmailDialog, AddFaxNumberDialog, AddPhoneNumberDialog, EmailRow, PhoneRow, CardRow }
})
export default class Overview extends Vue {
  @OrganizationStore.Getter(GET_ORGANIZATION) organization!: Organization | null;
  @OrganizationStore.Action(FETCH_ORGANIZATION) fetch!: () => Promise<ApiResponse<Organization>>;

  created() {
    this.fetch();
  }
}
